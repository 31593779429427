<template>
  <div class="page">
    <el-input
      placeholder="请输入搜索关键字"
      v-model="search"
      prefix-icon="el-icon-search"
      clearable
      class="search"
      @input="inputSearch"
      @blur="keyupSearch($event)"
      @keyup.enter.native="keyupSearch($event)"
    >
    </el-input>

    <el-tabs
      :stretch="true"
      v-model="activeName"
      @tab-click="handleClick"
      class="tabs"
    >
      <el-tab-pane label="热门职业" name="first">
        <div class="title title1">
          热门职业排名
        </div>
        <ul class="list" v-if="kaoHot.length > 0">
          <li
            v-for="(item, index) in kaoHot"
            :key="index"
            class="item"
            @click="handleDetail(item)"
          >
            <div class="cont">
              <img v-if="index == 0" src="../../assets/image/hot1.png" />
              <img v-else-if="index == 1" src="../../assets/image/hot2.png" />
              <img v-else-if="index == 2" src="../../assets/image/hot3.png" />
              <span v-else class="cont_num">{{ index + 1 }}</span>
              <div class="cont_name">
                {{ item.name }}
              </div>
            </div>
            <div class="hot">
              热度
              {{
                item.hits <= 10000
                  ? item.hits
                  : (item.hits / 10000).toFixed(1) + '万'
              }}
            </div>
          </li>
        </ul>
        <div v-else class="no_data">
          <img :src="noData" />
          <span class="text">暂无数据</span>
        </div>
        <div class="title title2">
          热门企业排名
        </div>
        <ul class="list" v-if="jobHot.length > 0">
          <li v-for="(item, index) in jobHot" :key="index" class="item">
            <div class="cont">
              <img v-if="index == 0" src="../../assets/image/hot1.png" />
              <img v-else-if="index == 1" src="../../assets/image/hot2.png" />
              <img v-else-if="index == 2" src="../../assets/image/hot3.png" />
              <span v-else class="cont_num">{{ index + 1 }}</span>
              <div class="cont_name">
                {{ item.name }}
              </div>
            </div>
            <div class="hot">
              热度
              {{
                item.hits <= 10000
                  ? item.hits
                  : (item.hits / 10000).toFixed(1) + '万'
              }}
            </div>
          </li>
        </ul>
        <div v-else class="no_data">
          <img :src="noData" />
          <span class="text">暂无数据</span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="全部职业" name="second">
        <div class="list1" v-if="listData.length > 0">
          <van-sidebar v-model="activeKey" @change="handleJob">
            <van-sidebar-item
              v-for="(item, index) in listData"
              :key="index"
              :title="item.name"
              :class="item.class ? 'listActive' : ''"
            ></van-sidebar-item>
          </van-sidebar>
          <div class="right">
            <template v-for="item in listData[activeKey].jobs">
              <div class="right_title">
                <!-- {{ item.name }} -->
                <span v-html="showKeyWord(item.name)"></span>
              </div>
              <ul class="right_list">
                <li
                  v-for="(ele, ind) in item.jobs"
                  :key="ind"
                  @click="handleDetail(ele)"
                >
                  <!-- {{ ele.name }} -->
                  <span v-html="showKeyWord(ele.name)"></span>
                </li>
              </ul>
            </template>
          </div>
        </div>
        <div v-else class="no_data">
          <img :src="noData" />
          <span class="text">暂无数据</span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import noData from '@/assets/image/nodata.png';

export default {
  components: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.userinfo,
      currentProvince: (state) => state.currentProvince,
      currentYear: (state) => state.currentYear,
      isNew: (state) => state.isNew,
    }),
  },
  data() {
    return {
      search: '',
      activeName: 'first', //'first'second,
      listData: [], //全部院校
      noData: noData,
      kaoHot: [], //报考热度排名
      jobHot: [], //就业前景排名
      activeKey: 0,
      showKey: false,
    };
  },

  mounted() {
    this.getJobHot();
    this.getFutureJobHot();
  },
  methods: {
    inputSearch() {
      if (!this.search) {
      this.showKey = false;
        this.listData = [];
        this.getJobList();
      }
    },
    keyupSearch(event) {
      event.target.blur();
      this.activeName = 'second';
      this.getJobList();
      this.showKey = true;
    },
    // 关键字标红
    showKeyWord(val) {
      let str = val;
      if (this.showKey) {
        let words = this.search;

        if (val.search(words) != -1) {
          str = str.replace(
            words,
            '<span style="color:#910000; font-weight: bold;font-size:16px;">' +
              words +
              '</span>'
          );
        }
      }

      return str;
    },
    // 关键字显示
    showKeyText(val) {
      let show = true;
      if (!this.showKey) return;
      let words = this.search;

      if (val.search(words) != -1) {
        show = true;
      } else {
        show = false;
      }
      return show;
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (this.activeName == 'first') {
        this.getJobHot();
        this.getFutureJobHot();
      }
      if (this.activeName == 'second') {
        this.getJobList();
      }
    },
    //全部专业
    async getJobList() {
      let apiUrl = '/php/job.list';
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        let newListData=[]
        if (res.data.ret == 0) {
          // this.listData = res.data.data;
          newListData = res.data.data;
          let list = [];
          let arr = [];
          if (this.search) {
            for (let i = 0; i < newListData.length; i++) {
              if (newListData[i].name.search(this.search) != -1) {
                newListData[i].class = true;
              } else {
                // newListData[i].class = null;
                for (let j = 0; j < newListData[i].jobs.length; j++) {
                  if (newListData[i].jobs[j].name.search(this.search) != -1) {
                    newListData[i].class = true;
                  } else {
                    // newListData[i].class = null;
                    for (
                      let z = 0;
                      z < newListData[i].jobs[j].jobs.length;
                      z++
                    ) {
                      if (
                        newListData[i].jobs[j].jobs[z].name.search(
                          this.search
                        ) != -1
                      ) {
                        newListData[i].class = true;
                      }
                    }
                  }
                }
              }
            }
          }
          this.listData = newListData;
          console.log(123333, this.listData);
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //专业排名-报考热度排名
    async getJobHot() {
      let apiUrl = `/php/job.hot?type=hot`;
      const data = {
        type: 'kaoHot',
      };
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.kaoHot = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },
    //专业排名-就业前景排名
    async getFutureJobHot() {
      let apiUrl = `/php/job.hot?type=future`;
      const data = {
        type: 'jobHot',
      };
      try {
        const res = await this.$axios.get(apiUrl);
        console.log('==res', res);
        if (res.data.ret == 0) {
          this.jobHot = res.data.data;
        }
      } catch (error) {
        console.log(`question error: ${error}`);
      }
    },

    //专业详情
    handleDetail(item) {
      console.log('====专业详情', item);
      this.$router.push({
        path: '/job/detail',
        query: { id: item.id, parentId: item.parentCode },
      });
    },
    handleNodeClick(data) {
      console.log(data);
    },
    handleJob(e) {
      console.log('handleJob', e);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  min-height: 100vh;
  background: #fff;
  padding-top: 50px;
}
.search {
  padding: 10px 12px;
  display: flex;
  align-items: center;

  /deep/ .el-input__inner {
    border: none !important;
    height: 40px !important;
    background-color: #f7f8fa;
    border-radius: 2px;
    color: #323233;
    font-size: 14px;
  }
  /deep/ .el-input__prefix {
    left: 15px;
    color: #323233;
  }
  /deep/ .el-input__suffix {
    right: 15px;
  }
}
.search_ul {
  position: absolute;
  background: #fff;
  z-index: 999;
  width: calc(100vw - 28px);
  left: 15px;
  border: 1px solid #ddd;
  border-top: none;
  /* top: 0; */
  margin-top: -10px;
  border-radius: 5px;
  padding: 10px 0;
  li {
    font-size: 14px;
    padding: 6px 20px;
  }
}
.van-dropdown-menu__item {
  justify-content: flex-end;
  padding-right: 20px;
}
.tabs {
  .el-tabs__header {
    border-bottom: 1px solid #f2f2f2;
    height: 44px;
    margin: 0;
    line-height: 44px;
  }
  .el-tabs__nav {
    // display: inline-flex;
    height: 44px;
    // justify-content: space-evenly;
    width: 100%;
  }
  .el-tabs__item {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-align: center;
    line-height: 42px;
    padding: 0 15px !important;
  }
  .el-tabs__item.is-active {
    color: #910000 !important;
  }
  /deep/ .el-tabs__active-bar {
    background-color: #910000;
    border-radius: 5px;
    width: 55px !important;
    margin-left: 52px;
  }
}
.title {
  font-size: 16px;
  color: #212121;
  font-weight: 700;
  margin: 20px 15px 0;
  padding: 10px 15px;
}
.title1 {
  background-image: url(../../assets/image/hot-major.png);
  background-size: 100%;
  background-repeat: no-repeat;
  color: #fd7a2a;
}
.title2 {
  background-image: url(../../assets/image/hot-company.png);
  background-size: 100%;
  background-repeat: no-repeat;
  color: #3697ff;
}
.van-sidebar-item--select {
  color: #222;
  font-weight: 800;
  border-color: #910000;
}
.van-sidebar-item.listActive {
  color: #910000;
  font-weight: bold;
}
.list {
  padding: 10px 15px;
  li.item {
    border-bottom: 1px solid #f2f2f2;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  li.item {
    font-size: 14px;
    width: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cont {
      color: #666;
      display: inline-flex;
      align-items: center;
      img {
        width: 20px;
      }
      .cont_num {
        color: #9b9b9b;
        font-size: 16px;
      }
      .cont_name {
        font-size: 16px;
        color: #212121;
        margin-left: 10px;
      }
      .hot {
        color: #666;
      }
    }
  }
}
.list1 {
  display: flex;
  .right {
    padding: 10px;
    width: 80%;
    height: 80vh;
    overflow: auto;
    .right_title {
      font-size: 18px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333;
    }
    .right_list {
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
        text-align: center;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        background: #f9f9f9;
        margin: 0 8px 8px 0;
        padding: 8px 10px;
        border-radius: 3px;
      }
    }
  }
}
.del {
  padding: 10px 15px 0;
  text-align: right;
  font-size: 14px;

  .icons {
    font-size: 18px;
    margin-right: 1px;
    vertical-align: text-top;
  }
}
.comp_box {
  padding-bottom: 80px;
  .comp_btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    padding: 10px 15px;
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 14px;
    background: #fff;
    .el-button--primary {
      background: #910000;
      border-color: #910000;
      opacity: 0.5;
      padding: 10px 30px;
    }
  }
}
.no_data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  width: 100%;
  flex-wrap: wrap;
  align-content: center;
  img {
    width: 180px;
  }
  .text {
    font-size: 16px;
    color: #666;
    text-align: center;
    width: 100%;
    font-weight: 600;
    padding-top: 20px;
  }
}
.el-message-box {
  width: 90%;
  .el-button {
    background: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
  }
  .el-button--primary {
    background: #910000;
    border-color: #910000;
    color: #fff;
  }
}
</style>
